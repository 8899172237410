.flex {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
.flex-center {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	justify-content: center;
}
.flex-column {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
}
.flex-wrap {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-wrap: wrap;
}
.justify-center {
	justify-content: center;
}
.justify-end {
	justify-content: flex-end;
}
.space-between {
	justify-content: space-between;
}
.space-around {
	justify-content: space-around;
}
.align-start {
	align-items: flex-start;
}
.align-center {
	align-items: center;
}
.align-stretch {
	align-items: stretch;
}
.align-end {
	align-items: flex-end;
}
.block {
	display: block;
}
.inline-block {
	display: block;
}
.none {
	display: none;
}
.tac {
	text-align: center;
}
.tal {
	text-align: left;
}
.tar {
	text-align: right;
}
.noHover{
	pointer-events: none;
}
.bold {
	font-weight: 600;
}
.italic {
	font-style: italic;
}