body {
	max-width: 700px;
	margin: 0 auto;
	padding: 5%;
	color: #333;
}
body * {
	font-family: Thasadith !important;
	font-weight: 600 !important;
	font-size: 18px;
	letter-spacing: .04rem;
	line-height: 125%;
}
.spinner {
	position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.logo {
	padding-bottom: 32px;
}
.intro svg {
	margin-right: 16px;
}
.intro .salutation {
	font-family: Amiri, Times New Roman !important;
	font-size: 24px;
	color: black;
	text-shadow: 0px 0px 10px rgb(0 0 0 / 20%);
}
@media (max-width: 500px) {
	body * {
		font-size: 16px;
	}
}